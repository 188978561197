import { makeVar } from "@apollo/client";

const dataSourceVar = makeVar({
  initialTypes: ["table", "strive_form"],
  selectedTypes: ["table", "strive_form"],
  limit: 20,
  offset: 0,
  selectedCount: null
});

export const updateAddCache = (cache, addDataSource, title) => {
  cache.modify({
    fields: {
      db_ninja_template_data_sources_view(existing = []) {
        return [...existing, { ...addDataSource, title: title }];
      },
      db_ninja_data_sources_with_template(existing = []) {
        return existing.filter(e => e.id != addDataSource.templateDataSource);
      }
    }
  });
};

export const updateDeleteCache = (cache, dataSource) => {
  cache.modify({
    fields: {
      db_ninja_template_data_sources_view(existing = []) {
        const newData = existing.filter(e => e.id != dataSource.dataSourceId);
        return [...newData];
      },
      db_ninja_data_sources_with_template(existing = []) {
        return [
          ...existing,
          {
            id: dataSource.id,
            type: dataSource.type,
            title: dataSource.title
          }
        ];
      }
    }
  });
};

export default dataSourceVar;
