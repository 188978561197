import { HASURA_ENDPOINT } from "../api/endpoint";

// your-app-name/src/fetchGraphQL.js
export default async (text, variables) => {
  const response = await fetch(HASURA_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      tenant: localStorage.tenant_id,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: text,
      variables
    })
  });
  return await response.json();
};
