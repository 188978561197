import { InMemoryCache } from "@apollo/client";
import dataSourceVar from "./dataSource";
import templateVar from "./template";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        template: {
          read() {
            return templateVar();
          }
        },
        dataSource: {
          read() {
            return dataSourceVar();
          }
        },
        db_ninja_data_sources_with_template: {
          keyArgs: ["in"],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          }
        }
      }
    }
  }
});
export default cache;
