import { ApolloClient, gql, HttpLink, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import cache from "./cache/cache";
import { HASURA_ENDPOINT, HASURA_SUBS_ENDPOINT } from "../api/endpoint";

const typeDefs = gql`
  type DataSource {
    selectedTypes: [String!]
    limit: Int!
    offset: Int!
  }

  type Template {
    templateId: Int!
  }

  extend type Query {
    dataSource: DataSource
    template: Template
  }
`;

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.token}`,
      tenant: localStorage.tenant_id
    }
  };
});

const httpLink = authLink.concat(
  new HttpLink({
    uri: HASURA_ENDPOINT,
    headers: {
      "Content-Type": "application/json"
    }
  })
);

const wsLink = new WebSocketLink({
  uri: HASURA_SUBS_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: () => {
      return {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      };
    }
  }
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link,
  cache,
  fetchOptions: {
    mode: "no-cors"
  },
  typeDefs
});
export default client;
