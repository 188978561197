import { commitLocalUpdate } from "react-relay";
import environment from "../RelayEnvironment";

export function selectTenant(tenant) {
  commitLocalUpdate(environment, store => {
    const fieldKey = "selectedTenant";
    const __typename = "SelectedTenant";

    const dataID = `client:${__typename}`;
    const record =
      store.getRoot().getLinkedRecord(fieldKey) ||
      store.create(dataID, __typename);
    record.setValue(tenant.tenant_id, "tenant_id");
    store.getRoot().setLinkedRecord(record, fieldKey);
  });
}

export function refreshTenant(refresh) {
  commitLocalUpdate(environment, store => {
    const fieldKey = "refreshTenant";
    const __typename = "RefreshTenant";

    const dataID = `client:${__typename}`;
    const record =
      store.getRoot().getLinkedRecord(fieldKey) ||
      store.create(dataID, __typename);
    record.setValue(refresh.refresh, "refresh");
    store.getRoot().setLinkedRecord(record, fieldKey);
  });
}
