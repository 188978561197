import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PageUrl from "../const/pageUrl";
import { socket, SocketContext } from "../socket/socket";
import Loadable from "react-loadable";

const DataSource = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DataSource" */ "../pages/dataSource/DataSource"
    ),
  loading: () => <div></div>
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "../pages/login/Login"),
  loading: () => <div></div>
});
const EditStriveFormDataFetcher = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EditStriveFormDataFetcher" */ "../pages/striveForms/editStriveForm/EditStriveFormDataFetcher"
    ),
  loading: () => <div></div>
});
const InitializeUser = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InitializeUser" */ "../pages/initializeUser/InitializeUser"
    ),
  loading: () => <div></div>
});
const Main = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ "../pages/main/Main"),
  loading: () => <div></div>
});
const Workspace = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Workspace" */ "../pages/workspace/Workspace"),
  loading: () => <div></div>
});
const SideBar = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SideBar" */ "../components/sidebar/SideBar"),
  loading: () => <div></div>
});
const Audience = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Audience" */ "../pages/audience/Audience"),
  loading: () => <div></div>
});
const Unsubscribe = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Unsubscribe" */ "../pages/unsubscribe/Unsubscribe"
    ),
  loading: () => <div></div>
});
const ProductDl = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProductDl" */ "../pages/userManagement/ProductDl"
    ),
  loading: () => <div></div>
});
const PrivacyPolicy = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PrivacyPolicy" */ "../pages/policies/PrivacyPolicy"
    ),
  loading: () => <div></div>
});
const TermsOfUsePolicy = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TermsOfUsePolicy" */ "../pages/policies/TermsOfUsePolicy"
    ),
  loading: () => <div></div>
});
const EmailStatistics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EmailStatistics" */ "../pages/emailStatistics/EmailStatistics"
    ),
  loading: () => <div></div>
});

const AppRouter = props => {
  const [usedSocket, setUsedSocket] = React.useState(null);

  React.useEffect(() => {
    setUsedSocket(socket(localStorage.token));
  }, [localStorage.token]);

  return (
    <SocketContext.Provider value={usedSocket}>
      <React.Suspense fallback={<div></div>}>
        <Switch>
          <Route
            exact={true}
            path={PageUrl.HOME}
            render={() => <Redirect to={PageUrl.WORKSPACE} />}
          />
          <Route
            exact={true}
            path={PageUrl.LOGIN}
            render={() => <Login {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.PRODUCTS}
            render={() => <Redirect to={PageUrl.WORKSPACE} />}
          />
          <Route
            exact={true}
            path={"/workspace"}
            render={() => <Redirect to={PageUrl.WORKSPACE} />}
          />
          <Route
            exact={true}
            path={PageUrl.INITIALIZE}
            render={() => <InitializeUser {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.TENANT_MEMBERS}
            render={() => <Workspace {...props} isTenantMembers={true} />}
          />
          <Route
            exact={true}
            path={PageUrl.TENANT_SETTINGS}
            render={() => <Workspace {...props} isTenantSettings={true} />}
          />
          <Route
            exact={true}
            path={PageUrl.AUDIENCE}
            render={() => (
              <SideBar {...props}>
                <Audience {...props} />
              </SideBar>
            )}
          />
          <Route
            exact={true}
            path={PageUrl.MAIN}
            render={() => <Main {...props} />}
          />

          <Route
            exact={true}
            path={PageUrl.EDIT_STRIVE_FORM}
            render={() => <EditStriveFormDataFetcher {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.WORKSPACE}
            render={() => <Workspace {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.DATASOURCE}
            render={() => <DataSource {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.USER}
            render={() => <ProductDl {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.EMAIL_STAT}
            render={() => <EmailStatistics {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.UNSUBSCRIBE}
            render={() => <Unsubscribe {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.PRIVACY_POLICY}
            render={() => <PrivacyPolicy {...props} />}
          />
          <Route
            exact={true}
            path={PageUrl.TERMS_OF_USE_POLICY}
            render={() => <TermsOfUsePolicy {...props} />}
          />
        </Switch>
      </React.Suspense>
    </SocketContext.Provider>
  );
};

export default AppRouter;
