import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { closeSnackbar } from "../../redux/actions/snackbar";
import { green, amber } from "@material-ui/core/colors";

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  margin: {
    margin: theme.spacing.unit
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const MySnackbarContent = props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const SnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const GenericSnackbar = props => {
  const { closeSnackbar, snackbar } = props;

  return (
    <div>
      {snackbar ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={closeSnackbar}
        >
          <SnackbarContentWrapper
            onClose={closeSnackbar}
            variant={snackbar.snackbarType}
            message={snackbar.message}
          />
        </Snackbar>
      ) : null}
    </div>
  );
};
export default connect(
  state => ({
    settings: state.settings,
    snackbar: state.snackbar
  }),
  dispatch =>
    bindActionCreators(
      {
        closeSnackbar
      },
      dispatch
    )
)(withStyles(styles)(GenericSnackbar));
