export const Cerulean = "#0095D9";
export const Primary = "#3f51b5";
export const Red = "red";
export const YellowSea = "#FFA90B";
export const Green = "green";
export const Gallery = "#EBEBEB";
export const Shark = "#212529";
export const White = "white";
export const Black = "black";
export const Grey = "#828282";
export const defaultEmailTextColor = "#475156";
export const TextGrey = "#949494";
export const BorderGrey = "#DCDEE3";
export const HeaderGrey = "#757575";
export const DropOuterGrey = "#E3E3E3";
export const DropInnerGrey = "#F0F0F0";
export const DropBorderGrey = "#fafafa";
export const HpPrimary = "#8599AD";
export const HpSecondaryBlue = "#2483FF";
export const BackgroundColor = "#F9F9F9";
export const Sienna = "#EB5757";
export const Silver = "#BDBDBD";
export const CuriousBlue = "#2D9CDB";
export const RoyalBlue = "#635ee7";
export const HpGreen = "#219653";
export const HpYellow = "#F2C94C";
export const HpPurple = "#9B51E0";
export const HpBlue = "#2F80ED";
export const WhiteOpacity = "rgba(255, 255, 255, 0.5)";
export const GreyText = "rgba(0, 0, 0, 0.54)";
export const PrimaryGray = "#93969C";
export const ActionBlue = "#2483FF";
export const DarkBlue = "#18223B";
export const LightBlue = "#EBEEF7";
export const backgroundBlue = "#F3F5F7";
export const borderBlue = "#80bdff";
export const LightBlueSecoundry = "#6DAAF9";
export const switchGreyColor = "#d7d9de";
export const emailEditorGrey = "#dee0e1";
export const emailEditorTextColor = "#475156";
export const emailEditorGreyTextColor = "#B5BABF";
export const focusedEmailEditorGreyTextColor = "#495156";
export const emailViewBorderGrey = "#dadada";
export const emailEditorEditIcon = "#E1E5ED";
export const emailEditorRed = "#E54158";
export const emailEditorYellow = "#FFBA00";
export const emailEditorGreen = "#72C804";
export const IconGrey = "#8599AD";
export const BackgroundGrey = "#F3F5F7";
export const CanvasColor = "#1C1C1C";
export const GaugePointsGrey = "#00A3D6";
export const EmailPointsBlue = "#e5f6fb";
export const EmailCommentBorder = "#DDE3E8";
export const EmailCommentsItalic = "#292C32";
export const KpiQuestionText = "#f4f5f5";
export const KpiText = "#a3a8ab";
export const TooltipColor = "#6C7384";
export const TooltipTextColor = "#EBEEF7";
export const EmailOverTimeBorder = "#dcdcdc";
export const EmailReponseGreen = "#7DC242";
export const EmailResponseYellow = "#FFBA00";
export const EmailResponseRed = "#E54158";
export const metaDataLabelGrey = "rgba(79, 86, 118, 0.5)";
export const PointsContainerBonusColor = "#d5e6a6";
export const PointTextBonusColor = "#77c043";
export const PrimaryTextColor = "#545A69";
export const emailEditorBorderColor = "rgba(231, 237, 243, 0.8)";
export const inputBackGroundLightBlue = "#EAEDF5";
export const listHeaderSettings = "#5D6476";
export const BtnBackGroundLightBlue = "#D4E6FF";
export const InputHoverBorderColor = "#CED5E8";
export const SelectBackGroundLightBlue = "#C0DBFF";
export const NumberPreviewColor = "#30373F";
export const DisabledInputField = "#F2F3F5";
export const GreySecondary = "#9496A1";
export const ScheduleActionBlue = "#006FFF";
export const ActionGreen = "#19CC8B";
export const ScheduleBorderColor = "#ECF1F5";
export const PrimaryBorderColor = "#DFE8EF";
export const AddRuleBg = "#F3F4F7";
export const SecondryActionBlue = "#5283FF";
export const DisabledTextGrey = "#C2C5CF";
export const boxShadowGrey = "#F3F3F3";
export const DarkBlueSecondary = "#4F5676";
export const SecondaryBlue = "#D4E7FF";
export const PrimaryHoverBlue = "#0464E0";
export const PrimaryPurple = "#8B6BFF";
export const PrimaryRed = "#FF4975";
export const SecondaryRed = "#FFEDEE";
export const SecondaryPurple = "#7070FA";
export const SecondaryGreen = "#DEFFE9";
export const ThirdGreen = "#8DCFB7";
export const secondaryGreyBorder = "#6DA9F5";
export const primaryYellow = "#FFC800";
export const GreyFields = "#F0F0F2";
export const SecondaryHoverBlue = "#A1CAFF";
export const ActionBlueDisabled = "#4F5669";
export const CommentsBackgroundcolor = "#f5f5f5";
export const CommentsTitlesubheaderColor = "#A1AAB0";
export const ComponentHoverBorder = "#9A9A9A";
export const DarkGrey = "#F0F0F2";
export const DarkGreyLabel = "#989799";
export const LightGrey = "#E0DFE4";
//we only should use the colors under this comment
export const PurplePrimary = "#7070FA";
export const PurplePrimaryLightFocus = "#A6A6FF";
export const PurplePrimaryLightHover = "#DEDEFF";
export const PurplePrimaryLight = "#EBEBFF";
export const PurplePrimaryHover = "#5555F2";
export const PurplePrimaryHoverNew = "#DADBFA";
export const PurpleLightHover = "#EBEBFF";
export const BluePrimary = "#3D79F0";
export const BlueSecondary = "#EDF3FF";
export const GreenActive = "#2B2A35";
export const GreenBackGround = "#E3F0EE";
export const RedPrimary = "#FF4975";
export const RedHover = "#FF2157";
export const RedLight = "#FFF4F7";
export const CommentsPink = "#F664BC";
export const Disabled80 = "#BEC0CC";
export const Surfaces85 = "#CACACC";
export const Surfaces98 = "#F6F6F9";
export const Surfaces99 = "#FCFCFD";
export const Surfaces100 = "#FFFFFF";
export const Dark85 = "#2B2A35";
export const Dark40 = "#605D65";
export const Dark60 = "#989799";
export const Dark66 = "#9C9EA8";
export const Dark89 = "#E0DFE4";
export const Dark95 = "#F0F0F2";
export const AdditionalMonteCarlo = "#82D2C2";
export const AdditionalTanHide = "#FD9855";
export const AdditionalGeraldine = "#FC7E92";
export const AdditionalHotPink = "#FE5EAE";
export const AdditionalHeliotrope = "#9B65FF";
export const AdditionalMalibu02 = "#67B4FD";
export const AdditionalAnakiwa = "#97CDFF";
export const AdditionalLavender = "#D468E3";
export const AdditionalCottonCandy = "#FFB8DB";
export const AdditionalVividTangerine = "#FD9C86";
export const AdditionalRajah = "#FAB970";
export const AdditionalMalibu01 = "#78E2F4";
