export default {
  HOME: "/",
  LOGIN: "/login",
  DATASOURCE: "/dataSource",
  USER: "/user",
  EMAIL_STAT: "/emailStatistics",
  PRODUCTS: "/products",
  TEMPLATE_CREATION: "/template creation",
  MAIN: "/main",
  STRIVE_FORMS: "/strive-forms",
  EDIT_STRIVE_FORM: "/strive-forms/:id",
  WORKSPACE: "/folder",
  UNSUBSCRIBE: "/productUnsubscribe",
  INITIALIZE: "/initialize",
  TENANT_MEMBERS: "/folder/editor",
  TENANT_SETTINGS: "/folder/settings",
  AUDIENCE: "/audience",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE_POLICY: "/terms-of-use-policy"
};
