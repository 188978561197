import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./app/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store, history } from "./store";
import { BrowserRouter } from "react-router-dom";
import { RelayEnvironmentProvider } from "relay-hooks";
import RelayEnvironment from "./graphql/RelayEnvironment";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/apollo";
import { datadogRum } from "@datadog/browser-rum";
const env = process.env.REACT_APP_ENV || "development";

if (env !== "development")
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
  });

render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <BrowserRouter store={store} history={history}>
          <App />
        </BrowserRouter>
      </RelayEnvironmentProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
