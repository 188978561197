// your-app-name/src/RelayEnvironment.js
import {
  commitLocalUpdate,
  Environment,
  Network,
  RecordSource,
  Store,
  Observable
} from "relay-runtime";
import FetchGraphQL from "./FetchGraphqlHelper.js";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { HASURA_SUBS_ENDPOINT } from "../api/endpoint.js";

async function fetchRelay(params, variables) {
  return FetchGraphQL(params.text, variables);
}

const networkSubscriptions = (request, variables) => {
  const subscribeObservable = new SubscriptionClient(HASURA_SUBS_ENDPOINT, {
    reconnect: true,
    connectionParams: () => {
      return {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      };
    }
  }).request({
    query: request.text,
    operationName: request.name,
    variables
  });
  return Observable.from(subscribeObservable);
};

// Export a singleton instance of Relay Environment configured with our network function:
const environment = new Environment({
  network: Network.create(fetchRelay, networkSubscriptions),
  store: new Store(new RecordSource())
});

commitLocalUpdate(environment, store => {
  const fieldKey = "selectedWorkspace";
  const __typename = "SelectedWorkspace";

  const dataID = `client:${__typename}`;
  const record = store.create(dataID, __typename);

  record.setValue(null, "id");

  environment.retain({
    request: { identifier: dataID },
    dataID,
    variables: {},
    node: { selections: [] }
  });

  store.getRoot().setLinkedRecord(record, fieldKey);
});

export default environment;
