import React from "react";
import AppContainer from "./AppContainer";

import { DialogServiceProvider } from "../services/DialogService";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog
} from "@material-ui/core";
import { themeLight } from "../stylesheet";

const App = props => {
  const [adBlockEnabled, setAdBlockEnabled] = React.useState(false);

  const checkAddBlocker = async () => {
    async function tryRequest() {
      try {
        return fetch(
          new Request(
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
            {
              method: "HEAD",
              mode: "no-cors"
            }
          )
        )
          .then(function(response) {
            return false;
          })
          .catch(function(e) {
            return true;
          });
      } catch (error) {
        return true;
      }
    }
    var blockResult = await tryRequest();
    setAdBlockEnabled(blockResult);
  };

  React.useEffect(() => {
    checkAddBlocker();
  }, []);
  return (
    <>
      <MuiThemeProvider theme={themeLight}>
        <DialogServiceProvider>
          <AppContainer {...props} />
        </DialogServiceProvider>
      </MuiThemeProvider>
      <Dialog
        open={adBlockEnabled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"AdBlock Chrome extension detected"}
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <div>You might be using an ad blocker.</div>
              <div>To continue using this site, please disable it.</div>
              <div style={{ display: "flex" }}>
                <div>Click this icon: </div>
                <img
                  style={{ height: 20, width: 20 }}
                  alt=""
                  src="https://storage.googleapis.com/download/storage/v1/b/strive-form-images/o/340900d7-5212-4954-83e5-00f3a19f68f5_Adblock_logo.png?generation=1614069897376270&alt=media"
                />
                <div> and disable always for this site!</div>
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default App;
