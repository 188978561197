import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppRouter from "./AppRouter";
import GenericSnackbar from "../components/generic snackbar/GenericSnackbar";
import { fetchProducts } from "../redux/actions/products";
import { fetchDataSourceConditions } from "../redux/actions/dataSourceCondition";
import { fetchIcons } from "../redux/actions/icons";
import { fetchCtlRules } from "../redux/actions/ctlRules";
import { fetchChannels } from "../redux/actions/channels";
import { VerifyEmail, AutoAuthenticateUser } from "../api/authApi";
import { GetPermissionByKey } from "../api/tenantApi";
import { setUser } from "../redux/actions/user";
import { setTenant } from "../redux/actions/tenant";
import jwt_decode from "jwt-decode";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Dialog,
  Button
} from "@material-ui/core";
import PageUrl from "../const/pageUrl";

import queryString from "query-string";
import { selectTenant } from "../graphql/updaters/tenant";
import { fetchDls } from "../redux/actions/ninjaDl";
import { CLJS_ENDPOINT } from "../api/endpoint";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppContainer = props => {
  const {
    settings,
    products,
    snippets,
    apps,
    fetchIcons,
    icons,
    dbTables,
    tenant,
    location,
    history,
    fetchChannels,
    setUser,
    setTenant,
    ninjaDl,
    fetchDls
  } = props;
  var data = require(`../assets/i18n/${
    settings.language ? settings.language : "en"
  }.json`);
  const [loading, setLoading] = React.useState(true);
  const [authTry, setAuthTry] = React.useState(false);

  const { invitation_key, verification_token } = queryString.parse(
    props.location.search
  );

  const [tenantPermissionFlag, setTenantPermissionFlag] = React.useState(false);
  const initApp = async () => {
    try {
      await autoAuthenticateUserFunc();
      if (
        !ninjaDl.loading &&
        !ninjaDl.fetched &&
        window.location.pathname != "/productUnsubscribe"
      ) {
        fetchDls();
      }

      setLoading(false);
    } catch (exp) {
      localStorage.clear();
      setLoading(false);
      window.location.replace(CLJS_ENDPOINT + "/login");
    }
    setAuthTry(true);
    const emptyLists =
      !products.data &&
      !snippets.data &&
      !dbTables.data &&
      !icons.data &&
      !apps.data &&
      window.location.pathname != "/productUnsubscribe";
    if (emptyLists) {
      fetchIcons();
      fetchChannels();
    }
  };

  const autoAuthenticateUserFunc = async () => {
    let decoded = null;
    decoded = jwt_decode(localStorage.token);
    var expiration = decoded.exp;

    if (expiration * 1000 < new Date().getTime()) {
      throw "token expired";
    }
    var authenticationResult = await AutoAuthenticateUser(
      localStorage.tenant_id ?? null
    );

    const { token } = authenticationResult.data;
    localStorage.token = token;
    decoded = jwt_decode(localStorage.token);
    if (
      localStorage.invitation_key != null &&
      localStorage.invitation_key.trim() != ""
    ) {
      try {
        var result = await GetPermissionByKey(localStorage.invitation_key);
        const { tenantId, token } = result.data;
        localStorage.token = token;
        localStorage.tenant_id = tenantId;
        localStorage.invitation_key = "";
        decoded = jwt_decode(token);
        setTenantPermissionFlag(true);
      } catch (exp) {
        localStorage.invitation_key = "";
      }
    }
    setUser(decoded);
    routeUser(decoded);
  };

  const routeUser = decoded => {
    setUser(decoded);
    if (decoded.tenants == null || decoded.tenants.length == 0) {
      history.replace(PageUrl.INITIALIZE);
      return;
    }
    var currentTenant;
    if (localStorage.tenant_id != null) {
      currentTenant = decoded.tenants.find(
        item => item.tenant_id == localStorage.tenant_id
      );
    }
    if (currentTenant == null && decoded.tenants && decoded.tenants.length) {
      currentTenant = decoded.tenants[0];
    }
    setTenant(currentTenant, false);
    selectTenant(currentTenant);
    // history.replace(PageUrl.WORKSPACE);
  };

  const verifyAccount = async verificationCode => {
    try {
      var response = await VerifyEmail(verificationCode);
      if (response && response.data && response.data.token) {
        localStorage.token = response.data.content.token;
        var decoded = jwt_decode(localStorage.token);
        routeUser(decoded);
        setLoading(false);
        return;
      }
      window.location.replace(CLJS_ENDPOINT + "/login");
    } catch (exp) {
      window.location.replace(CLJS_ENDPOINT + "/login");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      location.pathname.toLowerCase().includes(PageUrl.PRIVACY_POLICY) ||
      location.pathname.toLowerCase().includes(PageUrl.TERMS_OF_USE_POLICY)
    ) {
      setLoading(false);
      return;
    }
    if (invitation_key != null) {
      localStorage.invitation_key = invitation_key;
    }
    if (
      location.pathname.toLowerCase().includes(PageUrl.WORKSPACE) &&
      queryString.parse(location.search).token != null
    ) {
      localStorage.token = queryString.parse(location.search).token;
      initApp();
    }
    if (
      authTry == false &&
      !location.pathname.toLowerCase().includes("/login") &&
      localStorage.token != null &&
      localStorage.token.trim() != ""
    ) {
      initApp();
    } else if (verification_token) {
      verifyAccount(verification_token);
    } else if (localStorage.token == null || localStorage.token.trim() == "") {
      window.location.replace(CLJS_ENDPOINT + "/login");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="container">
      <IntlProvider locale={settings.language} messages={data}>
        <GenericSnackbar />
        {!loading ? <AppRouter {...props} /> : null}
      </IntlProvider>
      <Dialog
        open={tenantPermissionFlag && loading != true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setTenantPermissionFlag(false);
        }}
        aria-labelledby="add-new-project-title"
        aria-describedby="add-new-project-description"
      >
        <DialogTitle id="add-new-project-title">
          {"New project was added!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-new-project-description">
            {tenant.workspace_id != null ? "A new workspace " : tenant.name} was
            added to your projects.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTenantPermissionFlag(false);
            }}
            color="primary"
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(
  connect(
    state => ({
      settings: state.settings,
      products: state.products,
      snippets: state.snippets,
      dbTables: state.dbTables,
      icons: state.icons,
      apps: state.apps,
      workspaces: state.workspaces,
      activeChannel: state.activeChannel,
      user: state.user,
      tenant: state.tenant,
      ninjaDl: state.ninjaDl
    }),
    dispatch =>
      bindActionCreators(
        {
          fetchProducts,
          fetchIcons,
          fetchCtlRules,
          fetchDataSourceConditions,
          setUser,
          setTenant,
          fetchChannels,
          fetchDls
        },
        dispatch
      )
  )(AppContainer)
);
